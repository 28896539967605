<template>
    <div>
        <section class="box">
            <h1>{{ content.title }}</h1>
            <div class="data">
                <div class="left">
                    <div class="date">
                        <font-awesome-icon :icon="['fas', 'calendar-alt']" class="icon" />
                        {{ content.date }}
                    </div>
                    <div>
                        {{ content.view }} views
                    </div>
                </div>
                <div class="social">
                    <social-share></social-share>
                </div>
            </div>

            <article id="articleContent" class="content-article mt-4" v-html="content.detail">
            </article>
            
            <div class="tags mt-4" v-if="content.tags.length > 0">
                <strong>Tag</strong>
                <div class="list">
                    <div class="tag" v-for="(item, index) in content.tags" :key="index">
                        <span v-if="!item.link">{{ item.text }}</span>
                        <a :href="item.link" :title="item.text" v-if="item.link" target="_blank">
                            {{ item.text }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="author" v-if="content.authorProfile">
                <div class="portrait">
                    <b-icon icon="person" class="icon"></b-icon>
                </div>
                <div class="profile">
                    <label><b-icon icon="pencil" class="icon"></b-icon> ผู้เขียน</label>
                    <router-link :to="helper.replaceRouteParam(routerPath.AUTHOR_PROFILE, ':id', content.authorProfile.param)" :title="content.authorProfile.name">
                        {{ content.authorProfile.name }}
                    </router-link>
                </div>
            </div>
        </section>

        <section class="relate-section mt-3 mb-5">
            <div class="product-recommend">
                <div class="content-bar mb-2">
                    <div class="bar-title">ยางรถยนต์แนะนำ</div>
                    <router-link :to="routerPath.TIRE" class="view-more" title="ค้นหายางรถยนต์">ดูทั้งหมด <b-icon icon="chevron-right"></b-icon></router-link>
                </div>
                <div class="loading" v-if="!isRelatedProductReady">
                    <div class="item">
                        <loader-content :isDisplay="true"></loader-content>
                    </div>
                </div>
                <product-grid-blog
                    v-if="isRelatedProductReady"
                    :networkId="content.networkCode"
                    :products="relatedProduct.tires">
                </product-grid-blog>
            </div>

            <blog-relate
                :relatePromotions="relatePromotions"
                :relateArticlesLatest="relateArticlesLatest"
                :relateArticlesByCategory="relateArticlesByCategory">
            </blog-relate>
        </section>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import SocialShare from '@/components/blog/SocialShare';
import MixinArticleContent from '@/mixins/articleContent';
import RouterPath from '@/router/path';
import ProductService from '@/services/productService';
import BlogRelate from '@/components/blog/BlogRelate';
import ProductGridBlog from '@/components/product/ProductGridBlog';

export default {
    mixins: [ MixinArticleContent ],
	components: {
		SocialShare,
        BlogRelate,
        ProductGridBlog
	},
    data() {
        return {
            helper: Helper,
            routerPath: RouterPath,
            isRelatedProductReady: false,
            relatedProduct: null
        }
    },
    props: {
        content: {
            type: Object,
            default: null
        },
		relatePromotions: {
            type: Array,
            default: () => []
        },
		relateArticlesLatest: {
            type: Array,
            default: () => []
        },
		relateArticlesByCategory: {
            type: Array,
            default: () => []
        }
    },
	mounted() {
		this.getRelatedProduct();
    },
    methods: {
        async getRelatedProduct() {
            this.isRelatedProductReady = false;

            const articleId = Helper.splitDotParam(this.$route.params.key);
            const result = await ProductService.getRelatedProductByArticle(articleId);

			this.relatedProduct = result.data;
            
            this.isRelatedProductReady = true;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.box {
    padding: 20px;
    background: #fff;

    h1 {
        font-size: 25px;
        font-weight: bold;

        @media only screen and (max-width: $screenExtraSmall) {
            font-size: 20px;
        }
    }

    .data {
        display: flex;
        font-size: 13px;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $screenSmall) {
            display: block;
        }

        .left {
            display: flex;
            align-items: center;

            .date {
                margin-right: 20px;
                padding-right: 20px;
                border-right: 1px solid #333;
            }

            .icon {
                color: #ffcc00;
                margin-right: 5px;
            }
        }

        .social {
            @media only screen and (max-width: $screenSmall) {
                margin-top: 15px;
            }
        }
    }

    .author {
        border-top: 1px solid #ddd;
        margin-top: 25px;
        padding-top: 25px;
        display: flex;
        align-items: center;

        .portrait {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
            width: 50px;
            height: 50px;
            border: 1px solid #666;
            @include borderRadius(50%);

            .icon {
                font-size: 32px;
            }
        }

        .profile {
            margin-left: 20px;

            label {
                display: block;
                font-size: 12px;
                margin-bottom: 3px;
                color: #777;

                .icon {
                    margin-right: 5px;
                }
            }

            a {
                display: block;
                color: #333;
                font-weight: bold;
                font-size: 14px;
            }
        }
    }

    .tags {
        display: flex;
        align-items: center;

        strong {
            font-size: 13px;
            margin-right: 30px;
        }

        .list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .tag {
                padding: 3px 8px;
                margin: 3px;
                background: #e5e5e5;
                color: #000;
                font-size: 12px;

                a, span {
                    color: #000;
                    white-space: nowrap;
                }
            }
        }
    }
}

.product-recommend {
	.loading {
		.item {
			height: 250px;
		}
	}
}
</style>